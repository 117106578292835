import React, { Fragment, useCallback, useContext, useMemo } from 'react';

import { Box, Divider, Text } from '@chakra-ui/react';
import { useFieldArray } from 'react-final-form-arrays';
import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import { v4 as uuidv4 } from 'uuid';

import { useInquiry } from 'api/CompeonReverseApi/operation/queryHooks/inquiries';
import FormRow from 'components/FormRow';
import { InputWithField } from 'components/Input';
import InternationalZipCodeWithField from 'components/InternationalZipCodeWithField';
import { IAssociatedPerson } from 'models/CompanyDetails.model';
import {
  BENEFICIARY_BIRTH_COUNTRY,
  BENEFICIARY_BIRTHDAY,
  BENEFICIARY_COMPANY_SHARE,
  BENEFICIARY_FIRST_NAME,
  BENEFICIARY_GENDER,
  BENEFICIARY_LAST_NAME,
  BENEFICIARY_OWNER,
  BENEFICIARY_PRIVATE_ADDRESS,
  BENEFICIARY_PRIVATE_CITY,
  BENEFICIARY_PRIVATE_COUNTRY,
  BENEFICIARY_TAX_ID,
  BENEFICIARY_ZIP_CODE,
  EXISTING_BENEFICIARY,
} from 'modules/Inquiry/Form/formFields';
import { MultiSelectWithField } from 'modules/Inquiry/Form/Steps/RequestDetails/BankSearch/MultiSelect';
import { ParentFieldContext } from 'modules/InquiryFormNew/ParentField.context';
import CountriesComponent from 'pages/inquiryFlow/PeopleDetails/BeneficiaryOwnersSection/fields/CountriesComponent/CountriesComponent';
import { PeopleDetailsMultiSelectOption } from 'pages/inquiryFlow/PeopleDetails/MultiSelectPeopleDetails';
import {
  StyledPeopleInfo,
  StyledPeopleSection,
  StyledRemoveButton,
  StyledSinglePerson,
  StyledSinglePersonFields,
} from 'pages/inquiryFlow/PeopleDetails/styles';
import { mapAssociatedPerson } from 'pages/operationPortal/CompaniesDetails/helpers/mapPayload';
import { useFieldValidators } from 'shared/hooks/useFieldValidators';
import { store } from 'store';
import { getInquiryIdSelector } from 'store/inquiryDetails/selectors';
import { ButtonComponent } from 'theme/components/Button';
import AddIcon from 'theme/components/Icon/AddIcon';
import DeleteIcon from 'theme/components/Icon/DeleteIcon';
import { formatDateDays } from 'utils/date';
import { useTranslations } from 'utils/hooks/useTranslations';
import { getCurrentUser } from 'utils/user/getters';

import { BeneficiaryOwnersContext } from './BeneficiaryOwners.context';
import BeneficiaryBirthdate from './fields/BeneficiaryBirthdate';
import BeneficiaryFirstName from './fields/BeneficiaryFirstName';
import BeneficiaryGender from './fields/BeneficiaryGender';
import BeneficiaryLastName from './fields/BeneficiaryLastName';
import PrivateAddress from './fields/PrivateAddress';

export type FieldValue = {
  [BENEFICIARY_GENDER]: string;
  [BENEFICIARY_FIRST_NAME]: string;
  [BENEFICIARY_LAST_NAME]: string;
  [BENEFICIARY_COMPANY_SHARE]: string;
  [BENEFICIARY_TAX_ID]?: string;
  [BENEFICIARY_PRIVATE_ADDRESS]: string;
  [BENEFICIARY_ZIP_CODE]: string;
  [BENEFICIARY_PRIVATE_CITY]: string;
  [BENEFICIARY_PRIVATE_COUNTRY]: string;
  [BENEFICIARY_BIRTH_COUNTRY]: string;
  [BENEFICIARY_BIRTHDAY]: string;
  id: string;
  isAssociatedPerson: boolean;
};

interface BeneficiaryOwnerMultiSelectOption extends PeopleDetailsMultiSelectOption {
  firstName: string;
  lastName: string;
  gender: string;
  birthDate: Date;
  address: string;
  taxId?: string;
  birthPlace: string;
  city: string;
  companyShare: string;
  country: string;
  zipCode: string;
  type: 'legal_representatives' | 'beneficiary_owners';
  email: string;
}

type Fields = Exclude<keyof FieldValue, 'id' | 'isAssociatedPerson'>;

type SinglePersonProps = {
  onRemove: () => void;
  hideRemove: boolean;
  visibleFields?: Fields[];
  disableInputs?: boolean;
};

const SinglePerson = ({
  onRemove,
  hideRemove = false,
  disableInputs,
  visibleFields = [],
}: SinglePersonProps) => {
  const t = useTranslations('pages.peopleDetails.sections.beneficiaryOwners.fields.beneficiary');
  const { fieldName, userIndex } = useContext(BeneficiaryOwnersContext);

  return (
    <StyledSinglePerson>
      <StyledSinglePersonFields>
        <Text as="h3" mb={4}>
          {userIndex + 1}. {t('formHeading')}
        </Text>
        {!hideRemove && (
          <StyledRemoveButton onClick={onRemove} aria-label={t('removePerson')}>
            <DeleteIcon boxSize={6} />
          </StyledRemoveButton>
        )}
        {visibleFields.includes(BENEFICIARY_GENDER) ? (
          <FormRow>
            <BeneficiaryGender />
          </FormRow>
        ) : null}
        <FormRow>
          {visibleFields.includes(BENEFICIARY_FIRST_NAME) ? <BeneficiaryFirstName /> : null}
          {visibleFields.includes(BENEFICIARY_LAST_NAME) ? <BeneficiaryLastName /> : null}
        </FormRow>
        <FormRow>
          {visibleFields.includes(BENEFICIARY_ZIP_CODE) ? (
            <InternationalZipCodeWithField name={`${fieldName}.${BENEFICIARY_ZIP_CODE}`} />
          ) : null}
          {visibleFields.includes(BENEFICIARY_PRIVATE_CITY) ? (
            <InputWithField
              disabled={disableInputs}
              name={`${fieldName}.${BENEFICIARY_PRIVATE_CITY}`}
            />
          ) : null}
        </FormRow>
        {visibleFields.includes(BENEFICIARY_PRIVATE_COUNTRY) ? (
          <FormRow>
            <CountriesComponent
              disabled={disableInputs}
              name={`${fieldName}.${BENEFICIARY_PRIVATE_COUNTRY}`}
            />
          </FormRow>
        ) : null}
        {visibleFields.includes(BENEFICIARY_PRIVATE_ADDRESS) ? (
          <FormRow>
            <PrivateAddress disabled={disableInputs} />
          </FormRow>
        ) : null}
        <FormRow>
          {visibleFields.includes(BENEFICIARY_BIRTH_COUNTRY) ? (
            <CountriesComponent
              name={`${fieldName}.${BENEFICIARY_BIRTH_COUNTRY}`}
              disabled={disableInputs}
            />
          ) : null}
          {visibleFields?.includes(BENEFICIARY_BIRTHDAY) ? <BeneficiaryBirthdate /> : null}
        </FormRow>
      </StyledSinglePersonFields>
    </StyledSinglePerson>
  );
};

const DivOuterAddPerson = styled.div`
  margin-top: 20px;
`;

const userSubmittedAssociatedPersonVisibleFields = [
  BENEFICIARY_FIRST_NAME,
  BENEFICIARY_LAST_NAME,
  BENEFICIARY_PRIVATE_CITY,
  BENEFICIARY_PRIVATE_COUNTRY,
  BENEFICIARY_BIRTHDAY,
];

const associatedPersonVisibleFields = [
  BENEFICIARY_FIRST_NAME,
  BENEFICIARY_LAST_NAME,
  BENEFICIARY_PRIVATE_CITY,
  BENEFICIARY_PRIVATE_COUNTRY,
  BENEFICIARY_BIRTHDAY,
];

const BeneficiaryOwners = () => {
  const t = useTranslations('pages.peopleDetails.sections.beneficiaryOwners.fields.beneficiary');
  const placeholderTranslations = useTranslations('placeholders');
  const { fields } = useFieldArray<FieldValue>(BENEFICIARY_OWNER);
  const { fields: existingBeneficiaries } = useFieldArray<FieldValue>(EXISTING_BENEFICIARY);
  const inquiryId = useSelector(getInquiryIdSelector);
  const { isLoading, data } = useInquiry({
    variables: { id: inquiryId },
    enabled: !!inquiryId,
  });
  const user = getCurrentUser(store.getState());

  const beneficialOwners = useMemo(() => {
    if (!data) {
      return [];
    }
    return mapAssociatedPerson(data).filter(
      (p: IAssociatedPerson) => p.type === 'beneficiary_owners',
    );
  }, [data]);

  const { required } = useFieldValidators();
  const [search, setSearch] = React.useState('');

  const currentUserEmail = user?.email;

  const mapBeneficiariesFromAssociatedPerson = (person: IAssociatedPerson) => ({
    id: person.id,
    key: person.id,
    label: `${person.firstName} ${person.lastName}`,
    value: person.id,
    firstName: person.firstName,
    lastName: person.lastName,
    type: person.type,
    gender: person.salutation,
    address: person.address!!,
    taxId: person.taxId,
    birthDate: person.birthDate,
    birthPlace: person.birthPlace!!,
    city: person.city!!,
    companyShare: person.companyShare!!,
    country: person.country!!,
    zipCode: person.zipCode!!,
    email: person.email,
  });

  /*
  MultiSelect field does only work if used objects have a key property. Additionally
  the object needs a label since that is used for the displayed tags
  Without the key property you run into an eternal rerender
   */

  const associatedPersons = beneficialOwners
    .map<BeneficiaryOwnerMultiSelectOption>(mapBeneficiariesFromAssociatedPerson)
    .filter((p) => {
      return p.type === 'beneficiary_owners' && !(p.email === currentUserEmail);
    });

  const hasAssociatedPerson = associatedPersons.length > 0;

  const addPerson = useCallback(() => {
    const beneficiary = {
      [BENEFICIARY_GENDER]: '',
      [BENEFICIARY_FIRST_NAME]: '',
      [BENEFICIARY_LAST_NAME]: '',
      [BENEFICIARY_COMPANY_SHARE]: '',
      [BENEFICIARY_TAX_ID]: '',
      [BENEFICIARY_PRIVATE_ADDRESS]: '',
      [BENEFICIARY_ZIP_CODE]: '',
      [BENEFICIARY_PRIVATE_CITY]: '',
      [BENEFICIARY_PRIVATE_COUNTRY]: '',
      [BENEFICIARY_BIRTH_COUNTRY]: '',
      [BENEFICIARY_BIRTHDAY]: '',
      id: uuidv4(),
      isAssociatedPerson: false,
    };
    fields.push(beneficiary);
  }, [fields]);

  const options = associatedPersons.filter(({ label }) =>
    label.toLocaleLowerCase().includes(search.toLowerCase()),
  );

  const handleValidate = (value: unknown, fields: any) => {
    const field = fields[BENEFICIARY_OWNER];
    const isAdditionalPerson = field && field?.length > 0;
    return isAdditionalPerson ? undefined : required(value, fields);
  };

  const handleSelect = useCallback(
    (newPerson: BeneficiaryOwnerMultiSelectOption) => {
      const representative = {
        [BENEFICIARY_GENDER]: newPerson.gender,
        [BENEFICIARY_FIRST_NAME]: newPerson.firstName,
        [BENEFICIARY_LAST_NAME]: newPerson.lastName,
        [BENEFICIARY_COMPANY_SHARE]: newPerson.companyShare,
        [BENEFICIARY_TAX_ID]: newPerson.taxId,
        [BENEFICIARY_PRIVATE_ADDRESS]: newPerson.address,
        [BENEFICIARY_ZIP_CODE]: newPerson.zipCode,
        [BENEFICIARY_PRIVATE_CITY]: newPerson.city,
        [BENEFICIARY_PRIVATE_COUNTRY]: newPerson.country,
        [BENEFICIARY_BIRTH_COUNTRY]: newPerson.birthPlace,
        [BENEFICIARY_BIRTHDAY]: formatDateDays(newPerson.birthDate),
        id: newPerson.value.toString(),
        isAssociatedPerson: true,
        label: newPerson.label,
        key: newPerson.key,
      };
      fields.push(representative);
    },
    [fields],
  );

  const handelRemovePerson = useCallback(
    (index: number) => () => {
      if (existingBeneficiaries.length) {
        const indexToRemoveInExistingBeneficiaries = existingBeneficiaries.value.findIndex(
          (person) => person.id === fields.value[index].id,
        );
        if (indexToRemoveInExistingBeneficiaries > -1) {
          existingBeneficiaries.remove(indexToRemoveInExistingBeneficiaries);
        }
      }
      fields.remove(index);
    },
    [existingBeneficiaries, fields],
  );

  const handleRemove = useCallback(
    (keyToRemove: string) => {
      const indexToRemove = fields.value.findIndex((person) => person.id === keyToRemove);
      if (indexToRemove > -1) {
        handelRemovePerson(indexToRemove)();
      }
      if (existingBeneficiaries.length) {
        const indexToRemoveInExistingBeneficiaries = existingBeneficiaries.value.findIndex(
          (person) => person.id === keyToRemove,
        );

        if (indexToRemoveInExistingBeneficiaries > -1) {
          existingBeneficiaries.remove(indexToRemoveInExistingBeneficiaries);
        }
      }
    },
    [existingBeneficiaries, handelRemovePerson, fields.value],
  );

  const togglePerson = (person: BeneficiaryOwnerMultiSelectOption) => {
    if (fields.value?.findIndex(({ id }) => id === person.key) > -1) {
      handleRemove(person.key);
    } else {
      handleSelect(person);
    }
  };

  const handleSearch = (value: string) => {
    setSearch(value);
  };

  React.useEffect(() => {
    // when user selects he is not a beneficiary
    // if there is no beneficiary owner from backend and no new person added we need to push a new person
    if (!isLoading && associatedPersons.length === 0 && fields.length === 0) {
      addPerson();
    }
  }, [addPerson, associatedPersons.length, fields.length, isLoading]);

  const selectedOptions =
    associatedPersons.filter((person) => fields.value?.find((p) => p.id === person.key)) || [];
  return (
    <StyledPeopleSection>
      {hasAssociatedPerson && (
        <>
          <MultiSelectWithField
            name={EXISTING_BENEFICIARY}
            placeholder={placeholderTranslations('pleaseChoose')}
            options={options}
            validate={handleValidate}
            selectedOptions={selectedOptions}
            isLoading={false}
            onSelect={togglePerson}
            onRemove={handleRemove}
            onInputChange={handleSearch}
            data-testid={'select-field-' + EXISTING_BENEFICIARY}
          />
        </>
      )}
      {fields.length === 0 && (
        <>
          <StyledPeopleInfo>{t('footer')}</StyledPeopleInfo>
          <ButtonComponent
            leftIcon={<AddIcon boxSize={6} display="block" />}
            onClick={addPerson}
            data-testid="add-additional-beneficial-owner-btn"
          >
            {t('addPerson')}
          </ButtonComponent>
        </>
      )}
      {fields.map((fieldName, index) => (
        <Fragment key={fields.value[index].id}>
          <Divider opacity="1" mt={4} mb={8} borderColor="border.lightGrey" />
          <Box pb={4}>
            <ParentFieldContext.Provider
              value={{
                parentFieldName: '',
                parentIndex: 0,
              }}
            >
              <BeneficiaryOwnersContext.Provider value={{ userIndex: index, fieldName }}>
                <SinglePerson
                  disableInputs={fields.value[index]?.isAssociatedPerson || false}
                  visibleFields={
                    fields.value[index]?.isAssociatedPerson
                      ? associatedPersonVisibleFields
                      : userSubmittedAssociatedPersonVisibleFields
                  }
                  onRemove={handelRemovePerson(index)}
                  hideRemove={fields.length === 1 && !hasAssociatedPerson}
                />
              </BeneficiaryOwnersContext.Provider>
            </ParentFieldContext.Provider>
          </Box>
        </Fragment>
      ))}
      {fields.length ? (
        <DivOuterAddPerson>
          {fields.length === 1 ? <StyledPeopleInfo>{t('footer')}</StyledPeopleInfo> : null}
          <ButtonComponent
            leftIcon={<AddIcon boxSize={6} display="block" />}
            onClick={addPerson}
            data-testid="add-additional-beneficial-owner-btn"
          >
            {t('addPerson')}
          </ButtonComponent>
        </DivOuterAddPerson>
      ) : null}
    </StyledPeopleSection>
  );
};

export default React.memo(BeneficiaryOwners);
