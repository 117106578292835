import React from 'react';

import PhoneInput, { Country } from 'react-phone-number-input';
// Not possible to import dynamically because the packages cannot be resolved corredtly. Thus we catch non-imported locales in the default case below
import deLabels from 'react-phone-number-input/locale/de.json';
import enLabels from 'react-phone-number-input/locale/en.json';
import frLabels from 'react-phone-number-input/locale/fr.json';
import { useSelector } from 'react-redux';

import { getSelectedLanguageSelector } from 'components/App/LanguageProvider/store/selectors';
import ControlWrapper from 'components/ControlWrapper';
import withFormField from 'utils/form/withFormField';

import { InputWrapper } from './styles';
import InputContainer from '../Input/InputWrapper/InputContainer';

const PhoneNumberInput = (props: any) => {
  const { onChange, input, onBlur, testId, fieldName, ...rest } = props;
  const locale = useSelector(getSelectedLanguageSelector);
  let labels = deLabels;
  switch (locale) {
    case 'de':
      labels = deLabels;
      break;
    case 'en':
      labels = enLabels;
      break;
    case 'fr':
      labels = frLabels;
      break;
    default:
      throw new Error(
        'Unsupported locale in the PhoneNumberInput component. Please add the labels for the current locale to the component.',
      );
  }

  return (
    <ControlWrapper {...rest}>
      <InputContainer>
        <InputWrapper>
          <PhoneInput
            labels={labels}
            international
            withCountryCallingCode
            defaultCountry={(locale.toUpperCase() as Country) || 'DE'}
            onChange={onChange}
            onBlur={onBlur}
            value={input.value}
            data-testId={fieldName}
          />
        </InputWrapper>
      </InputContainer>
    </ControlWrapper>
  );
};

export const PhoneNumberField = withFormField(PhoneNumberInput, { showErrorsWhenPristine: false });
