import React, { useContext } from 'react';

import { PhoneNumberField } from 'components/PhoneNumberField';
import { REPRESENTATIVE_PHONE_NUMBER } from 'modules/Inquiry/Form/formFields';
import { LegalRepresentativesContext } from 'pages/inquiryFlow/PeopleDetails/LegalRepresentationSection/LegalRepresentatives/LegalRepresentatives.context';
import { useFieldValidators } from 'shared/hooks/useFieldValidators';
import { combineValidators } from 'utils/validators';

const RepresentativePhoneNumber: React.FC = () => {
  const { fieldName } = useContext(LegalRepresentativesContext);
  const { required, isPhoneNumberReal } = useFieldValidators();

  return (
    <>
      <PhoneNumberField
        initialValue={null}
        name={`${fieldName}.${REPRESENTATIVE_PHONE_NUMBER}`}
        autoComplete="tel"
        validate={combineValidators(isPhoneNumberReal, required)}
      />
    </>
  );
};

export default RepresentativePhoneNumber;
