import React from 'react';

import { Box, BoxProps } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import Condition from 'components/Condition';
import MultipleFilesBlock from 'components/UploadBlock/NewFileRequestBlock/MultipleFilesBlock';
import FileSectionTitle from 'pages/customerPortal/InquiryDetails/DocumentExchange/FileSectionTitle';
import {
  getAdditionalCustomerFilesWithoutFileRequest,
  getNumOfNewAdditionalFilesUploadedForOperation,
} from 'store/documentExchange/documentExchange.selectors';
import { useTranslations } from 'utils/hooks/useTranslations';

type AdditionalFilesFromCustomerProps = Pick<BoxProps, 'mb'>;

// Shows the addtional files uploaded by the customer to the '/files' endpoint
const AdditionalFilesFromCustomer: React.FC<AdditionalFilesFromCustomerProps> = ({ mb }) => {
  const files = useSelector(getAdditionalCustomerFilesWithoutFileRequest);
  const tUpload = useTranslations('components.upload');
  const tDocEx = useTranslations('pages.inquiryDetails.documentExchange');
  const numOfNewFiles = useSelector(getNumOfNewAdditionalFilesUploadedForOperation) ?? 0;

  return (
    <Condition condition={Boolean(files.length)}>
      <section>
        <Box mb={mb}>
          <FileSectionTitle>
            {tDocEx('additionalFilesfromClient')}
            {!!numOfNewFiles && ` (${numOfNewFiles} ${tUpload('newFile')})`}
          </FileSectionTitle>
          <MultipleFilesBlock
            title={tUpload('otherDocuments.title')}
            files={files}
            showFileDownloadStatusBadge
          />
        </Box>
      </section>
    </Condition>
  );
};

export default AdditionalFilesFromCustomer;
